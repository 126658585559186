<template></template>

<script>
import router from "@/router";
import { Dialog, Toast } from "vant";
import { defineComponent } from "vue";
import wx from "weixin-js-sdk";

export default defineComponent({
  name: "home",
  setup() {
    const { currentRoute } = router;
    const { code } = currentRoute.value.query;

    if (code) {
      let codeStr = code + "";
      location.replace("http://mcds.lvbutc.com?code=" + codeStr);
    }
    return { backlen };
  },
});
</script>

<style></style>
